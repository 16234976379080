import { addDays } from "date-fns";
import { IHotelSearch } from "../interfaces/hotels.interface";
import {
  HOTEL_BOOKING_POLICY_FILTERS,
  HOTEL_BOOKING_SUPPLIER_FILTERS,
  HOTEL_PROPERTY_RATINGS_FILTERS,
} from "@/ag-portal-common/constants/hotels";

const hotelState: IHotelSearch = {
  isLoading: false,
  isPropertiesFetching: false,
  isLocationsFetching: false,
  isPropertyDetailFetching: false,
  initiateBookingLoading: false,
  isConfirmBookingLoading: false,
  isCancellingConfirmedBooking: false,
  isPayingForBooking: false,
  isHotelIssueLoading: false,
  isHotelOtpResendLoading: false,
  propertyResponse: {
    hotels: [],
    filters: null,
    current: 1,
    has_previous: false,
    has_next: false,
    searched_property_not_found: null,
  },
  selectedFilters: {
    priceRange: [0, 0],
    propertyRating: HOTEL_PROPERTY_RATINGS_FILTERS.map(
      (filter) => filter.value
    ),
    bookingPolicy: HOTEL_BOOKING_POLICY_FILTERS.map((filter) => filter.value),
    supplier: HOTEL_BOOKING_SUPPLIER_FILTERS.map((filter) => filter.value),
  },
  travelers: [],
  mobileNumber: null,
  email: null,
  maxPriceRange: 0,
  minPriceRange: 0,
  propertyDetail: null,
  locations: [],
  selectedLocation: null,
  checkin_date: new Date(),
  checkout_date: addDays(new Date(), 1),
  hotel_adult_count: 1,
  hotel_child_count: 0,
  hotel_child_age: [],
  bookingDetailsResponse: null,
};

export default hotelState;
