import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPriceSummary = _resolveComponent("AgPriceSummary")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_heading, {
        variant: "h2",
        title: "Cancellation Policy",
        class: "margin_bottom_10"
      }),
      _withDirectives(_createVNode(_component_AgPriceSummary, {
        "test-id": "",
        info: "Free Cancellation until",
        price: _ctx.freeCancellationDate,
        class: "margin_bottom_10"
      }, null, 8, ["price"]), [
        [_vShow, _ctx.freeCancellationDate]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nonRefundablePolicies, (term, index) => {
        return (_openBlock(), _createBlock(_component_AgDiv, { key: index }, {
          default: _withCtx(() => [
            _createVNode(_component_AgPriceSummary, {
              "test-id": "",
              info: _ctx.renderCancellationDate(term.start_date),
              price: 
          _ctx.getPrice(
            term.cancellation_amount.value,
            term.cancellation_amount.currency
          )
        
            }, null, 8, ["info", "price"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}