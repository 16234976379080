<template>
  <div class="otp-dialog">
    <div class="header">
      <AgHeading title="Enter OTP" variant="h3" test-id="" />
      <AgButton @click="closeDialog" variant="danger" type="button"
        >X
      </AgButton>
    </div>
    <p class="instruction">
      Please enter the OTP sent to your registered email address
    </p>

    <div class="otp-input">
      <v-text-field
        class="digit-input"
        v-for="(_, index) in otpDigits"
        :key="index"
        type="text"
        v-model="otpDigits[index]"
        maxlength="1"
        @keydown="handleInput(index, $event)"
        @paste="handlePaste"
        :ref="renderOTPRef(index)"
      />
    </div>
    <p v-show="error" class="error">{{ error }}</p>

    <div class="action-buttons">
      <AGButton
        test-id=""
        @click="submitOTP"
        :is-loading="isSubmitOTPloading"
        variant="primary"
        type="button"
        >Submit
      </AGButton>
      <AGButton
        test-id=""
        @click="resendOTP"
        :disabled="isResendOTPDisabled"
        variant="secondary"
        type="button"
        >Resend OTP
      </AGButton>
    </div>
  </div>
</template>

<script lang="ts">
import analytics from "@/analytics.service";
import { defineComponent } from "vue";
import { HOTEL_ANALYTICS_EVENTS } from "../../constants/analyticsEvents";

export default defineComponent({
  name: "OtpDialogBody",
  data(): { otpDigits: Array<string>; error: string | null } {
    return {
      otpDigits: ["", "", "", "", "", ""],
      error: "",
    };
  },
  computed: {
    isSubmitOTPloading(): boolean {
      return this.$store.state.hotelModule.isHotelIssueLoading;
    },
    isResendOTPDisabled(): boolean {
      return this.isSubmitOTPloading;
    },
  },
  methods: {
    renderOTPRef(index: number) {
      return `otpRef-${index}`;
    },
    closeDialog() {
      this.$emit("handleCloseDialog");
    },
    handleInput(index: number, event: KeyboardEvent) {
      this.error = "";
      if (event.key === "Backspace") {
        // Delete the current digit and move focus to the previous input field
        if (index > 0) {
          this.otpDigits[index] = "";
          const ele = this.$refs["otpRef-" + (index - 1)] as HTMLInputElement[];
          ele[0].focus();
        }
      } else if (this.otpDigits[index]) {
        // Move focus to the next input field
        if (index < this.otpDigits.length - 1) {
          const ele = this.$refs["otpRef-" + (index + 1)] as HTMLInputElement[];
          ele[0].focus();
        } else {
          // Focus out from the last input field
          const ele = this.$refs["otpRef-" + index] as HTMLInputElement[];
          ele[0].blur();
        }
      }
    },
    handlePaste(event: ClipboardEvent) {
      event.preventDefault();
      const pastedText = event.clipboardData?.getData("text/plain") || "";
      const otpArray = pastedText.trim().split("");
      otpArray.forEach((digit, index) => {
        if (index < this.otpDigits.length) {
          this.otpDigits[index] = digit;
        }
      });
      this.$nextTick(() => {
        const lastInput = this.$refs[
          `otpRef-${otpArray.length - 1}`
        ] as HTMLInputElement[];
        lastInput[0].blur();
      });
    },
    submitOTP() {
      const otp = this.otpDigits.join("").replace(/^\s+|\s+$/g, "");
      if (otp.length === 6) {
        const booking_id = this.$route.params?.id;
        analytics.logActionEvent(
          HOTEL_ANALYTICS_EVENTS.AG_HOTEL_BOOKING_ISSUANCE,
          { booking_id }
        );
        this.$store.dispatch("issueHotelBooking", {
          payload: { booking_id, otp },
          successCallback: this.closeDialog,
        });
      } else {
        this.error = "Please enter all digits of OTP";
      }
    },
    resendOTP() {
      const booking_id = this.$route.params?.id;
      this.$store.dispatch("createOTP", { booking_id: booking_id });
    },
  },
});
</script>

<style scoped>
.otp-dialog {
  max-width: 400px;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #8e2121;
  margin-bottom: 15px;
}

.instruction {
  text-align: center;
  margin-bottom: 15px;
}

.otp-input {
  display: flex;
  justify-content: center;
}

.digit-input {
  width: 40px;
  height: 45px;
  text-align: center;
  margin: 10px 5px;
  background: #bababa;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
}

.error {
  font-weight: bold;
  color: red;
  margin-top: 10px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
