import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgSelect = _resolveComponent("AgSelect")!
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgTravelDetail = _resolveComponent("AgTravelDetail")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_AgCard = _resolveComponent("AgCard")!

  return (_openBlock(), _createBlock(_component_AgCard, { "test-id": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        icon: "contactTravelerIcon",
        title: "Traveler Details"
      }),
      _createVNode(_component_ag_accordion, {
        class: "flight_accordion_wrap margin_bottom_0",
        "panel-value": [0]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_accordion_panel, null, {
            flightInfo: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                variant: "h3",
                title: _ctx.renderAccordionTitle,
                class: "margin_bottom_0"
              }, null, 8, ["title"])
            ]),
            tabSection: _withCtx(() => [
              _createVNode(_component_AgRow, {
                "test-id": "",
                class: "padding_top_40"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgColumn, {
                    "test-id": "",
                    md: "7",
                    lg: "7",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgRow, { "test-id": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AgColumn, {
                            "test-id": "",
                            md: "12",
                            lg: "9"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AgSelect, {
                                items: _ctx.renderTitleData,
                                onChange: _cache[0] || (_cache[0] = (value) => _ctx.handleFieldChange(value,'title')),
                                value: _ctx.getValueByNameandIndex('title'),
                                label: "Title",
                                variant: "outlined",
                                "test-id": "",
                                error: _ctx.renderError('title'),
                                id: _ctx.generateIds('title')
                              }, null, 8, ["items", "value", "error", "id"]),
                              _createVNode(_component_AGTextField, {
                                label: "First Name and Middle Name (if any)",
                                onInput: _cache[1] || (_cache[1] = (value) => _ctx.handleTextFieldChange(value, 'first_name')),
                                value: _ctx.getValueByNameandIndex('first_name'),
                                "test-id": "",
                                variant: "outlined",
                                error: _ctx.renderError('first_name'),
                                id: _ctx.generateIds('first_name')
                              }, null, 8, ["value", "error", "id"]),
                              _createVNode(_component_AGTextField, {
                                label: "Last Name",
                                onInput: _cache[2] || (_cache[2] = (value) => _ctx.handleTextFieldChange(value, 'last_name')),
                                value: _ctx.getValueByNameandIndex('last_name'),
                                "test-id": "",
                                variant: "outlined",
                                error: _ctx.renderError('last_name'),
                                id: _ctx.generateIds('last_name')
                              }, null, 8, ["value", "error", "id"]),
                              _createVNode(_component_ag_calendar, {
                                label: "Date of Birth",
                                "onUpdate:date_of_birth": _cache[3] || (_cache[3] = (value) => _ctx.handleDateSelect(value, 'date_of_birth')),
                                "calendar-name": "date_of_birth",
                                date: 
                      _ctx.$store.state.hotelModule.travelers[_ctx.index]['date_of_birth']
                    ,
                                "min-date": _ctx.disabledDates.to,
                                "max-date": _ctx.disabledDates.from,
                                "year-range": _ctx.disabledDates.yearsToShow,
                                error: _ctx.renderError('date_of_birth'),
                                id: _ctx.generateIds('date_of_birth')
                              }, null, 8, ["date", "min-date", "max-date", "year-range", "error", "id"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AgColumn, {
                    "test-id": "",
                    md: "5",
                    lg: "5",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgTravelDetail, {
                        "test-id": "",
                        items: {
                  [_ctx.TravelerCardDetails.TITLE]: _ctx.getValueByNameandIndex('title'),
                  [_ctx.TravelerCardDetails.FIRST_NAME]:
                    _ctx.getValueByNameandIndex('first_name'),
                  [_ctx.TravelerCardDetails.LAST_NAME]:
                    _ctx.getValueByNameandIndex('last_name'),
                  [_ctx.TravelerCardDetails.DOB]: _ctx.dateOfBirth,
                }
                      }, {
                        headingArea: _withCtx(() => [
                          _createVNode(_component_AgHeading, {
                            variant: "h2",
                            title: "Contact Details",
                            class: "margin_bottom_5"
                          })
                        ]),
                        _: 1
                      }, 8, ["items"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}