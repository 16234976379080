import { RouteRecordRaw } from "vue-router";
import HelloWorld from "@/components/HelloWorld.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import HotelRoutes from "@/modules/Hotel/route";
import HotelSearch from "@/modules/Hotel/views/HotelSearch.vue";

import { PATH } from "@/ag-portal-common/constants/path";

const HomeRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "hotels",
    children: HotelRoutes,
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
  },
];

export default HomeRoutes;
