export enum HotelEndPoints {
  GET_ALL_LOCATIONS = "v2/hotels/get_all_locations",
  GET_ALL_PROPERTIES_V2 = "v2/hotels/properties/",
  GET_ALL_REGIONS = "v1/hotels/get_all_regions",
  GET_ALL_PROPERTIES = "v1/hotels/properties/",
  GET_PROPERTY_DETAIL = "v1/hotels/detail/",
  GET_PROPERTY_ROOM_DETAILS = "/v1/hotels/rooms/",
  INITIATE_BOOKING = "/v1/hotels/bookings/initiate/",
  VALIDATE_WEB_REFERENCE = "v1/hotels/booking/validate_web_reference",
  BOOK = "/v1/hotels/booking/book/",
  ISSUE = "/v1/hotels/bookings/issue/",
  GET_BOOKING = "/v2/bookings/",
  CONFIRM_BOOKING = "/v1/hotels/bookings/confirm/",
  CREATE_OTP = "/account-otp/create",
}

export enum HotelEndPointsV3 {
  GET_ALL_LOCATIONS = "v4/hotels/locations", // vf3/hotels/locations (done)
  GET_ALL_PROPERTIES_V3 = "v5/hotels/properties/", // v3 moved
  GET_ALL_REGIONS = "v4/hotels/locations", // ye ab hogai ha v3/hotels/locations
  GET_ALL_PROPERTIES = "v5/hotels/properties/", // get all properties and get all properties v3 are same
  GET_PROPERTY_DETAIL = "v4/hotels/detail/", // v3 (done)
  GET_PROPERTY_ROOM_DETAILS = "/v1/hotels/rooms/", // not migrated
  INITIATE_BOOKING = "/v4/hotels/bookings/initiate/", // v3 moved (done)
  VALIDATE_WEB_REFERENCE = "v1/hotels/booking/validate_web_reference", // not migrated
  BOOK = "/v1/hotels/booking/book/", //not migrated
  ISSUE = "/v4/hotels/bookings/issue/", // v3 (done)
  GET_BOOKING = "/v4/bookings/", // not migrated
  CONFIRM_BOOKING = "/v5/hotels/bookings/confirm/", // v3 (done)
  CANCEL_BOOKING = "v4/hotels/bookings/{0}/cancel/",
  PAY_NOW_BOOKING = "v4/hotels/bookings/{0}/pay-now/",
  CREATE_OTP = "/account-otp/create",
  RESEND_OTP = "v3/hotels/bookings/resend-otp/", // not used any where yet
}

export enum TourEndPoints {
  GET_ALL = "v1/tours/",
  GET_TOUR_DETAILS = "v1/tours/",
  INITIATE_BOOKING = "v1/tours/bookings/initiate/",
}

export enum TravelersType {
  ADULTS = "Adults",
  CHILDREN = "Children",
}

export const TravelersLimit = 6;

export const TravelersTypeLimit = {
  [TravelersType.ADULTS]: 1,
  [TravelersType.CHILDREN]: 1,
};

export const ChildrenAges = [
  {
    label: "1",
    value: 0,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
];

export const HOTEL_PROPERTY_RATINGS_FILTERS = [
  {
    label: "1 Star",
    value: "1",
  },
  {
    label: "2 Star",
    value: "2",
  },
  {
    label: "3 Star",
    value: "3",
  },
  {
    label: "4 Star",
    value: "4",
  },
  {
    label: "5 Star",
    value: "5",
  },
];

export const HOTEL_BOOKING_POLICY_FILTERS = [
  {
    value: "refundable",
    label: "Refundable",
  },
  {
    value: "non-refundable",
    label: "Non-Refundable",
  },
];

export const HOTEL_BOOKING_SUPPLIER_FILTERS = [
  {
    value: "ALPHA",
    label: "ALPHA",
  },
  {
    value: "BETA",
    label: "BETA",
  },
];

export const CHECK_IN_FORMAT = "yyyy-MM-dd";
export const DATE_FORMAT = "dd/MMM/yyyy";

export const DEFAULT_FILTERS = {
  amenities: [],
  booking_policy: [],
  payment_type: [],
  price: {
    max: 1000,
    min: 0,
  },
  property_class: [],
  property_type: [],
  region_id: [],
  sort: {
    name: "featured",
    order: "DESC",
  },
};

export const BOARDBASIS_CHIPS_CONFIG = {
  breakfast: {
    name: "Breakfast Included",
    // color: theme.colors.darkBlue,
    color: "",
  },
  freeairportTransfer: {
    name: "Free Airport Transfer",
    // color: theme.colors.darkBlue,
    color: "",
  },
  refundable: {
    name: "Refundable",
    // color: theme.colors.secondaryGreen,
    color: "",
  },
  freeCancellation: {
    name: "Free Cancellation",
    // color: theme.colors.secondaryGreen,
    color: "",
  },
};

export const HOTEL_CARD_CHIP = {
  BED_AND_BREAKFAST: {
    name: "Bed and Breakfast",
    label: "Breakfast Included",
  },
  BREAKFAST: {
    name: "Breakfast",
    label: "Breakfast Included",
  },
  FREE_AIRPORT_TRANSFER: {
    name: "Free Airport Transfer",
    label: "Free Airport Transfer",
  },
  FREE_CANCELLATION: {
    name: "Free Cancellation",
    label: "Free Cancellation",
  },
  NON_REFUNDABLE: {
    name: "Non-Refundable",
    label: "Non-Refundable",
  },
  REFUNDABLE: {
    name: "Refundable",
    label: "Refundable",
  },
};

export const HOTEL_INCLUSIVE_TAXES_NOTE = "Inclusive of all taxes";

export enum HOTEL_EVENTS {
  SEARCH_BUTTON = "hotel_search",
  SELECT_ROOM = "hotel_search_initiated",
  FILTER_APPLIED = "hotel_filter_applied",
  FILTER_REMOVED = "hotel_filter_removed",
  HOTEL_LIST_SCREEN = "hotel_list_screen",
  HOTEL_SEARCH_DESTINATION_CLICK = "hotel_search_destination_click",
}

export enum BOOKING_POLICIES {
  INSTANT_CONFIRMATION = "Instant Confirmation",
  ON_REQUEST = "On Request",
}

export const ON_REQUEST_TEXT =
  "Please note that the selected property is currently running at high occupancy levels. However, we will make every effort to ensure that your booking is confirmed after receipt of payment. In the unlikely event that the booking is refused, your amount shall be fully refunded within 24 hours.";
