import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_HotelSearchBar = _resolveComponent("HotelSearchBar")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgPriceRange = _resolveComponent("AgPriceRange")!
  const _component_AgFilterDropdown = _resolveComponent("AgFilterDropdown")!
  const _component_AgCheckbox = _resolveComponent("AgCheckbox")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_AgFlightChip = _resolveComponent("AgFlightChip")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgHotelSearchList = _resolveComponent("AgHotelSearchList")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!

  return (_ctx.propertiesLoading)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_ag_heading, {
          variant: "h2",
          title: "Search Results"
        }),
        _createVNode(_component_HotelSearchBar),
        _createVNode(_component_AgCard, { class: "flightFilterWrap" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgDiv, { class: "head" }, {
              default: _withCtx(() => [
                _createTextVNode(" Filter")
              ]),
              _: 1
            }),
            _createVNode(_component_AgDiv, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Price Range"
                }, {
                  Items: _withCtx(() => [
                    _createVNode(_component_AgPriceRange, {
                      "onUpdate:rangeSliderValue": _ctx.handleUpdateRange,
                      min: _ctx.minPriceRange,
                      max: _ctx.maxPriceRange,
                      "thumb-size": 20,
                      "thumb-label": "always",
                      "test-id": ""
                    }, null, 8, ["onUpdate:rangeSliderValue", "min", "max"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Property Rating",
                  onClick: _cache[2] || (_cache[2] = (e) => e.stopPropagation())
                }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratingFilter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        onClick: _cache[0] || (_cache[0] = (e) => e.stopPropagation()),
                        modelValue: _ctx.localSelectedFilters.propertyRating,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localSelectedFilters.propertyRating) = $event)),
                        key: index,
                        value: item.value,
                        label: item.label,
                        "test-id": ""
                      }, null, 8, ["modelValue", "value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Supplier"
                }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supplierFilter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        onClick: _cache[3] || (_cache[3] = (e) => e.stopPropagation()),
                        modelValue: _ctx.localSelectedFilters.supplier,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localSelectedFilters.supplier) = $event)),
                        key: index,
                        value: item.value,
                        label: item.label
                      }, null, 8, ["modelValue", "value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, {
                  "test-id": "",
                  label: "Booking Policy"
                }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refundableFilters, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        onClick: _cache[5] || (_cache[5] = (e) => e.stopPropagation()),
                        modelValue: _ctx.localSelectedFilters.bookingPolicy,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localSelectedFilters.bookingPolicy) = $event)),
                        key: index,
                        value: item.value,
                        label: item.label,
                        "test-id": ""
                      }, null, 8, ["modelValue", "value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_AgRow, { "test-id": "" }, {
          default: _withCtx(() => [
            _createVNode(_component_AgColumn, {
              "test-id": "",
              sm: "8",
              md: "8",
              cols: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgDiv, {
                  "test-id": "",
                  class: "d-flex margin_bottom_10",
                  style: {"flex-wrap":"wrap"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRatingFliter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgFlightChip, {
                        onClick: ($event: any) => (_ctx.handleRemoveChip(index, 'propertyRating')),
                        key: index,
                        value: item,
                        "test-id": ""
                      }, null, 8, ["onClick", "value"]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedBookingPolicyFliter, (item, index) => {
                      return (_openBlock(), _createBlock(_component_AgFlightChip, {
                        onClick: ($event: any) => (_ctx.handleRemoveChip(index, 'bookingPolicy')),
                        key: index,
                        value: item,
                        "test-id": ""
                      }, null, 8, ["onClick", "value"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProperties, (item, index) => {
          return (_openBlock(), _createBlock(_component_AgHotelSearchList, {
            class: "hotelsList",
            key: index,
            hotelThumb: _ctx.formatUrl(item?.main_image_url),
            hotelName: item?.property_name,
            address: 
        _ctx.renderAddress(item?.address_line_1, item?.city_name, item?.country_name)
      ,
            featured: item?.non_refundable,
            "prepayment-chip-text": 
        item?.issue_now_pay_later ? 'No prepayment needed' : ''
      ,
            refundType: _ctx.formatRefundable(item?.non_refundable),
            startingFrom: "Starting From",
            price: _ctx.renderPrice(item?.gross_price.value, item?.gross_price.currency),
            days: _ctx.getNights,
            rating: item?.rating,
            travelInfo: _ctx.getTravelersCount,
            supplierName: item?.supplier
          }, {
            action: _withCtx(() => [
              _createVNode(_component_a_g_button, {
                variant: "primary",
                class: "full-width view_button"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    class: "text-white",
                    target: "_blank",
                    to: _ctx.getPropertyDetailRoute(item?.property_id)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Show Details ")
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["hotelThumb", "hotelName", "address", "featured", "prepayment-chip-text", "refundType", "price", "days", "rating", "travelInfo", "supplierName"]))
        }), 128)),
        (_ctx.showNoResult)
          ? (_openBlock(), _createBlock(_component_AgNotFound, {
              key: 0,
              "test-id": "",
              heading: "No Results Found",
              description: "Please Try Modify Your Filters OR Try Again"
            }))
          : _createCommentVNode("", true)
      ], 64))
}