<template>
  <ag-loader v-if="$store.state.hotelModule.isLoading" />
  <ag-row v-else>
    <ag-column cols="12" sm="12" md="12" lg="9">
      <AgSelectedFlightInfo>
        <template #pnrInfo>
          <ag-card>
            <AgRow test-id="" class="v-center">
              <AgColumn test-id="" md="9">
                <AgSelectedFlightInfo>
                  <template #pnrInfo>
                    <AgDiv class="booking_id_wrap"
                      >Booking ID: <span>{{ renderBookingId }}</span></AgDiv
                    >
                  </template>
                </AgSelectedFlightInfo>
              </AgColumn>
              <AgColumn test-id="" md="3" class="text-right">
                <AGButton
                  test-id=""
                  type="button"
                  :variant="renderStatusSeverity"
                >
                  {{ renderBookingStatus }}
                </AGButton>
              </AgColumn>
            </AgRow>
          </ag-card>
        </template>
      </AgSelectedFlightInfo>

      <AgPropertyReview
        test-id=""
        :rating="bookingDetails?.property?.rating"
        :hotel-thumb="formatUrl(bookingDetails?.property.main_image_url || '')"
        :hotel-name="roomQoute(bookingDetails?.property?.name)"
        :room-quote="roomQoute(bookingDetails?.room?.room_type)"
        :checkin-date="renderDate(bookingDetails?.checkin)"
        :checkout-date="renderDate(bookingDetails?.checkout)"
        :no-of-night="
          getNights(bookingDetails?.checkin, bookingDetails?.checkout)
        "
      >
        <template #hotelImages>
          <agdiv style="display: flex; flex-wrap: wrap; align-items: center">
            <agdiv style="display: flex; flex-wrap: wrap">
              <FaciltyChip
                v-for="(item, index) in displayedFacilities"
                :key="index"
                :name="item.value"
                :icon-name="item.Icon"
              />
            </agdiv>
          </agdiv>
        </template>
      </AgPropertyReview>
      <ag-heading
        variant="h2"
        title="Passenger Details"
        class="margin_bottom_20"
      />
      <PassengerDetailCard
        v-for="(guest, index) in bookingDetails?.guests"
        :key="index"
        :guest="guest"
      />
    </ag-column>

    <ag-column cols="12" sm="12" md="12" lg="3">
      <PaymentPolicy
        v-if="bookingDetails?.ag_payment_date"
        :ag-payment-date="bookingDetails?.ag_payment_date"
      />
      <CancellationPolicy
        :cancellation-terms="bookingDetails?.room?.cancellation_terms"
      />

      <PriceSummary
        :propert-name="bookingDetails?.room?.room_type"
        :total-currency="
          bookingDetails?.price.currency + ' ' + bookingDetails?.price.value
        "
        :traveler-count="bookingDetails?.guests?.length"
      >
        <template #hoteltDetailsActionButtons>
          <AGButton
            v-show="canProceedToIssuance"
            @click="openIssuanceDialog"
            class="full-width margin_bottom_10"
            test-id=""
            >Proceed To Issuance
          </AGButton>
          <AGButton
            v-show="isStatusIssuedButNotPaid"
            :is-loading="isCancellingConfirmedBooking"
            @click="isCancelBookingDialogOpen = true"
            class="full-width margin_bottom_10"
            :test-id="genTestId(ELEMENT_TYPES.BUTTON, 'cancel-booking')"
            >Cancel Booking
          </AGButton>
          <AGButton
            v-show="isStatusIssuedButNotPaid"
            :is-loading="isPayingForBooking"
            @click="onPayNow"
            class="full-width margin_bottom_10"
            :test-id="genTestId(ELEMENT_TYPES.BUTTON, 'pay-now-booking')"
            >Pay Now
          </AGButton>
        </template>
      </PriceSummary>
    </ag-column>
  </ag-row>
  <HotelIssuanceDialog
    :is-open="isIssuanceDialogOpen"
    :handle-close-dialog="handleCloseDialog"
  />
  <CancelBookingConfirmationDialog
    :is-open="isCancelBookingDialogOpen"
    @handleAccept="onCancelBooking"
    @handleReject="isCancelBookingDialogOpen = false"
  />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { differenceInDays, format } from "date-fns";

import HotelIssuanceDialog from "../components/HotelIssuanceDialog.vue";
import PassengerDetailCard from "../components/PassengerDetailCard.vue";
import CancellationPolicy from "../components/CancellationPolicy.vue";
import { formatImageUrl, genTestId } from "@/ag-portal-common/utils/helpers";
import {
  BookingDetailsResponse,
  HotelDetails,
} from "@/ag-portal-common/types/hotel";
import PriceSummary from "../components/PriceSummary.vue";
import FaciltyChip from "../components/FacilityChip.vue";
import { getHotelFacility, IHotelFacility } from "../utils/hotelFacilities";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { getConfirmOrInitiateDate, isExpired } from "../utils";
import CancelBookingConfirmationDialog from "@/ag-portal-common/components/CancelBookingConfirmationDialog.vue";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import PaymentPolicy from "@/modules/Hotel/components/PaymentPolicy.vue";
import _ from "lodash";

export default defineComponent({
  name: "HotelPropertyBookingForm",
  components: {
    PaymentPolicy,
    CancelBookingConfirmationDialog,
    HotelIssuanceDialog,
    PassengerDetailCard,
    CancellationPolicy,
    PriceSummary,
    FaciltyChip,
  },
  data() {
    return {
      errors: {},
      isCancelBookingDialogOpen: false,
      isIssuanceDialogOpen: false,
      showAllFacilities: false,
    };
  },
  methods: {
    genTestId,
    onPayNow() {
      let bookingId =
        this.$store.getters.bookingDetailsResponse?.booking_id || "";
      this.$store.dispatch("payForBooking", bookingId);
    },
    onCancelBooking() {
      this.isCancelBookingDialogOpen = false;
      let bookingId =
        this.$store.getters.bookingDetailsResponse?.booking_id || "";
      this.$store.dispatch("cancelConfirmedBooking", bookingId);
    },
    renderDate(date: string) {
      return date ? format(new Date(date), "EEEE, MMM d, yyyy") : "";
    },
    getNights(checkin: string, checkout: string) {
      const nights = checkin
        ? differenceInDays(new Date(checkout), new Date(checkin))
        : 1;
      return `${nights} Night${nights > 1 ? "s" : ""} Stay`;
    },
    formatUrl(url: string) {
      return formatImageUrl(url);
    },
    roomQoute(roomType: string) {
      return `${roomType} - 1 ROOM`;
    },
    handleCloseDialog() {
      this.isIssuanceDialogOpen = false;
    },
    openIssuanceDialog() {
      const booking_id = this.$route.params?.id;
      this.$store.dispatch("createOTP", { booking_id: booking_id });

      this.isIssuanceDialogOpen = true;
    },
    fetchBookingDetails() {
      const bookingId = this.$route.params?.id;
      this.$store.dispatch("fetchBookingDetails", bookingId);
    },
  },
  computed: {
    displayedFacilities() {
      const bookingDetails = this.$store.getters.bookingDetails;
      const formattedFacilities = bookingDetails?.property?.facilities?.map(
        (item: any) => {
          return getHotelFacility(item);
        }
      );
      return formattedFacilities;
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
    renderStatusSeverity() {
      switch (_.capitalize(this.renderBookingStatus)) {
        case BOOKING_STATUS.CANCELED:
          return "danger";
        case BOOKING_STATUS.CONFIRMED:
          return "primary";
        case BOOKING_STATUS.ISSUED:
          return "primary";
        default:
          return "primary";
      }
    },
    renderBookingStatus() {
      return this.$store.getters.bookingDetailsResponse?.status || "";
    },
    bookingDetails(): HotelDetails {
      localStorage.setItem(
        "bookingDetails",
        JSON.stringify(this.$store.getters.bookingDetails)
      );
      return this.$store.getters.bookingDetails;
    },
    isPayingForBooking(): boolean {
      return this.$store.getters.isPayingForBooking;
    },
    isCancellingConfirmedBooking(): boolean {
      return this.$store.getters.isCancellingConfirmedBooking;
    },
    renderBookingId(): string {
      const bookingId = this.$route.params?.id as string;
      return bookingId;
    },
    facilities(): IHotelFacility[] {
      const bookingDetails: HotelDetails = this.$store.getters.bookingDetails;
      const formattedFacilities = bookingDetails?.property?.facilities?.map(
        (item) => {
          return getHotelFacility(item);
        }
      );
      return formattedFacilities;
    },
    isStatusIssuedButNotPaid(): boolean {
      const bookingDetailsResponse: BookingDetailsResponse =
        this.$store.getters.bookingDetailsResponse;
      const bookingStatus = bookingDetailsResponse?.status || "";
      return bookingStatus === BOOKING_STATUS.ISSUED_BUT_NOT_PAID.toUpperCase();
    },
    canProceedToIssuance(): boolean {
      let permissions = inject(AUTH_CONTEXT_KEYS.permissions) as () =>
        | PERMISSIONS[]
        | null;
      let permissionsList: PERMISSIONS[] = permissions() || [];

      const hasPermission = permissionsList.find(
        (permission: PERMISSIONS) => permission === PERMISSIONS.CAN_ISSUE_HOTEL
      );
      // Check the booking status and expiration as before
      const bookingDetailsResponse: BookingDetailsResponse =
        this.$store.getters.bookingDetailsResponse;
      const bookingStatus = bookingDetailsResponse?.status || "";
      const initiated_booking_expires_at =
        bookingDetailsResponse?.initiated_booking_expires_at;
      const confirmed_booking_expires_at =
        bookingDetailsResponse?.confirmed_booking_expires_at;
      const statusConfirmed =
        bookingStatus?.toUpperCase() === BOOKING_STATUS.CONFIRMED.toUpperCase();
      const bookingExpired = isExpired(
        getConfirmOrInitiateDate(
          bookingStatus,
          initiated_booking_expires_at,
          confirmed_booking_expires_at
        )
      );

      return statusConfirmed && !bookingExpired && Boolean(hasPermission);
    },
  },
  mounted() {
    this.fetchBookingDetails();
  },
});
</script>
