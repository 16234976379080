<template>
  <div
    style="
      display: flex;
      background: #f1ecec;
      padding: 11px;
      align-items: center;
      border-radius: 7px;
      margin-right: 10px;
      margin-bottom: 10px;
    "
  >
    <ag-icon class="margin_right_10" :name="iconName" />
    <p style="margin: 0">{{ name }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FaciltyChip",
  props: {
    iconName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
});
</script>
