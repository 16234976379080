import { GetterTree } from "vuex";
import { IHotelSearch } from "../interfaces/hotels.interface";

const getters: GetterTree<IHotelSearch, IHotelSearch> = {
  isPropertiesLoading: (state): boolean => state.isPropertiesFetching,
  locations: (state) => state.locations,
  isLocationsFetching: (state): boolean => state.isLocationsFetching,
  isPropertyDetailFetching: (state): boolean => state.isPropertyDetailFetching,
  isBookingInitiateLoading: (state): boolean => state.initiateBookingLoading,
  hotelChildAges: (state) => state.hotel_child_age,
  selectedFilters: (state) => state.selectedFilters,
  propertyDetail: (state) => state.propertyDetail,
  hotels: (state) => state.propertyResponse.hotels,
  hotelLocation: (state) => {
    return {
      lat: state.propertyDetail?.latitude,
      lng: state.propertyDetail?.longitude,
    };
  },
  bookingDetails: (state) => state.bookingDetailsResponse?.hotel_details,
  bookingDetailsResponse: (state) => state.bookingDetailsResponse,
  travelers: (state) => state.travelers,
  isConfirmBookindLoading: (state) => state.isConfirmBookingLoading,
  isCancellingConfirmedBooking: (state) => state.isCancellingConfirmedBooking,
  isPayingForBooking: (state) => state.isPayingForBooking,
};

export default getters;
