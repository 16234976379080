import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import { HotelSearchSuggestion } from "@/ag-portal-common/types/hotel";
import { isBefore, format } from "date-fns";

export const formatQueryParamsforHotelSearch = (
  adult_count: number,
  child_age_count: Array<number>,
  selectedLocation: HotelSearchSuggestion,
  checkin_date: Date,
  checkout_date: Date
) => {
  const query = {
    name: selectedLocation.display_name,
    sub_name: selectedLocation.sub_display_name,
    destination: selectedLocation.search_id,
    type: selectedLocation.type,
    checkin: format(checkin_date, "yyyy-MM-dd"),
    checkout: format(checkout_date, "yyyy-MM-dd"),
    adult: adult_count,
    ...(child_age_count?.length && { child: child_age_count.toString() }),
  };
  return query;
};

export const isExpired = (from: Date, to?: Date): boolean => {
  return isBefore(from, to || new Date());
};

export const getConfirmOrInitiateDate = (
  status: string,
  initiated_booking_expires_at: string,
  confirmed_booking_expires_at: null | string
): Date => {
  if (status.toLowerCase() === BOOKING_STATUS.CONFIRMED.toLowerCase())
    return new Date(confirmed_booking_expires_at as string) || "Invalid Date";
  return new Date(initiated_booking_expires_at as string);
};
