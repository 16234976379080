import { RouteRecordRaw } from "vue-router";
import HotelSearch from "@/modules/Hotel/views/HotelSearch.vue";
import HotelSearchResults from "@/modules/Hotel/views/HotelSearchResults.vue";
import HotelPropertyView from "@/modules/Hotel/views/HotelPropertyView.vue";
import HotelPropertyBookingForm from "@/modules/Hotel/views/HotelPropertyBookingForm.vue";
import HotelBookingDetails from "@/modules/Hotel/views/HotelBookingDetails.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.HOTELS,
    name: "hotelSearch",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.PERMISSIONS,
    },
    component: HotelSearch,
  },
  {
    path: PATH.HOTELS_SEARCH_RESULT,
    name: "hotelSearchResults",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.PERMISSIONS,
    },
    component: HotelSearchResults,
  },
  {
    path: PATH.HOTEL_PROPERTY_VIEW,
    name: "hotelPropertyView",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.PERMISSIONS,
    },
    component: HotelPropertyView,
  },
  {
    path: PATH.HOTEL_PROPERTY_BOOKING_FORM,
    name: "hotelPropertyBookingForm",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.PERMISSIONS,
    },
    component: HotelPropertyBookingForm,
  },
  {
    path: PATH.HOTEL_BOOKING_DETAILS,
    name: "hotelBookingDetails",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.PERMISSIONS,
    },
    component: HotelBookingDetails,
  },
];

export default routes;
