import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","background":"#f1ecec","padding":"11px","align-items":"center","border-radius":"7px","margin-right":"10px","margin-bottom":"10px"} }
const _hoisted_2 = { style: {"margin":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_icon = _resolveComponent("ag-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ag_icon, {
      class: "margin_right_10",
      name: _ctx.iconName
    }, null, 8, ["name"]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ]))
}