<template>
  <ag-loader v-if="propertiesLoading" />

  <template v-else>
    <ag-heading variant="h2" title="Search Results" />
    <HotelSearchBar />
    <!-- Filters -->
    <AgCard class="flightFilterWrap">
      <AgDiv class="head"> Filter</AgDiv>
      <AgDiv class="d-flex">
        <AgFilterDropdown test-id="" label="Price Range">
          <template #Items>
            <AgPriceRange
              @update:rangeSliderValue="handleUpdateRange"
              :min="minPriceRange"
              :max="maxPriceRange"
              :thumb-size="20"
              thumb-label="always"
              test-id=""
            ></AgPriceRange>
          </template>
        </AgFilterDropdown>

        <AgFilterDropdown
          test-id=""
          label="Property Rating"
          @click="(e:MouseEvent) => e.stopPropagation()"
        >
          <template #Items>
            <AgCheckbox
              v-for="(item, index) in ratingFilter"
              @click="(e:MouseEvent) => e.stopPropagation()"
              v-model="localSelectedFilters.propertyRating"
              :key="index"
              :value="item.value"
              :label="item.label"
              test-id=""
            />
          </template>
        </AgFilterDropdown>

        <AgFilterDropdown test-id="" label="Supplier">
          <template #Items>
            <AgCheckbox
              v-for="(item, index) in supplierFilter"
              @click="(e:MouseEvent) => e.stopPropagation()"
              v-model="localSelectedFilters.supplier"
              :key="index"
              :value="item.value"
              :label="item.label"
            />
          </template>
        </AgFilterDropdown>

        <AgFilterDropdown test-id="" label="Booking Policy">
          <template #Items>
            <AgCheckbox
              v-for="(item, index) in refundableFilters"
              @click="(e:MouseEvent) => e.stopPropagation()"
              v-model="localSelectedFilters.bookingPolicy"
              :key="index"
              :value="item.value"
              :label="item.label"
              test-id=""
            ></AgCheckbox>
          </template>
        </AgFilterDropdown>
      </AgDiv>
    </AgCard>
    <!-- Filter Chips -->
    <AgRow test-id="">
      <AgColumn test-id="" sm="8" md="8" cols="12">
        <AgDiv
          test-id=""
          class="d-flex margin_bottom_10"
          style="flex-wrap: wrap"
        >
          <AgFlightChip
            v-for="(item, index) in selectedRatingFliter"
            @click="handleRemoveChip(index, 'propertyRating')"
            :key="index"
            :value="item"
            test-id=""
          />
          <AgFlightChip
            v-for="(item, index) in selectedBookingPolicyFliter"
            @click="handleRemoveChip(index, 'bookingPolicy')"
            :key="index"
            :value="item"
            test-id=""
          />
        </AgDiv>
      </AgColumn>
    </AgRow>
    <!-- Hotel Cards -->
    <AgHotelSearchList
      class="hotelsList"
      v-for="(item, index) in filteredProperties"
      :key="index"
      :hotelThumb="formatUrl(item?.main_image_url)"
      :hotelName="item?.property_name"
      :address="
        renderAddress(item?.address_line_1, item?.city_name, item?.country_name)
      "
      :featured="item?.non_refundable"
      :prepayment-chip-text="
        item?.issue_now_pay_later ? 'No prepayment needed' : ''
      "
      :refundType="formatRefundable(item?.non_refundable)"
      startingFrom="Starting From"
      :price="renderPrice(item?.gross_price.value, item?.gross_price.currency)"
      :days="getNights"
      :rating="item?.rating"
      :travelInfo="getTravelersCount"
      :supplierName="item?.supplier"
    >
      <template #action>
        <a-g-button variant="primary" class="full-width view_button">
          <router-link
            class="text-white"
            target="_blank"
            :to="getPropertyDetailRoute(item?.property_id)"
            >Show Details
          </router-link>
        </a-g-button>
      </template>
    </AgHotelSearchList>
    <!-- Results no found -->
    <AgNotFound
      v-if="showNoResult"
      test-id=""
      heading="No Results Found"
      description="Please Try Modify Your Filters OR Try Again"
    />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { cloneDeep } from "lodash";

import HotelSearchBar from "../components/HotelSearchBar.vue";
import {
  DEFAULT_FILTERS,
  HOTEL_BOOKING_POLICY_FILTERS,
  HOTEL_BOOKING_SUPPLIER_FILTERS,
  HOTEL_PROPERTY_RATINGS_FILTERS,
} from "@/ag-portal-common/constants/hotels";
import { getPreHotelsSuggestions } from "@/ag-portal-common/constants/preHotelSuggestions";
import {
  HotelPropertyResponse,
  HotelSearchSuggestion,
  Property,
} from "@/ag-portal-common/types/hotel";
import {
  formatImageUrl,
  formatQueryPath,
  formatStringToRoutePath,
  getCurrencyFormatter,
  formatNumber,
} from "@/ag-portal-common/utils/helpers";
import { differenceInDays } from "date-fns";
import { formatQueryParamsforHotelSearch } from "../utils";
import { PATH } from "@/ag-portal-common/constants/path";
import { SearchFilters } from "../interfaces/hotels.interface";
import analyticsService from "@/analytics.service";
import { HOTEL_ANALYTICS_EVENTS } from "../constants/analyticsEvents";

export default defineComponent({
  name: "HotelSearchResults",
  data(): {
    localSelectedFilters: SearchFilters;
    filteredProperties: Array<Property>;
    minPriceRange: number;
    maxPriceRange: number;
    allowRouterQueryRequest: boolean;
    currentPage: number;
    items: any;
    itemObserver: any;
    apiCallInProgress: boolean;
  } {
    return {
      localSelectedFilters: {
        propertyRating: [],
        bookingPolicy: [],
        rangeSlider: 0,
        supplier: [],
      },
      filteredProperties: [],
      minPriceRange: 0,
      maxPriceRange: 0,
      allowRouterQueryRequest: true,
      currentPage: 1,
      items: null,
      itemObserver: null,
      apiCallInProgress: false,
    };
  },
  components: {
    HotelSearchBar,
  },
  methods: {
    async fetchHotelPropertiesForScroll() {
      if (!this.apiCallInProgress) {
        this.apiCallInProgress = true;
        await this.loadMoreProperties();
        this.items = document.querySelectorAll(".hotelsList");
        const thirdLastItem =
          this.items[this.items.length - Math.ceil(this.items.length / 3)];
        this.resetObserver(thirdLastItem);

        this.apiCallInProgress = false;
      }
    },
    resetObserver(thirdLastItem: Element) {
      if (this.itemObserver) {
        this.itemObserver.disconnect();
      }
      this.itemObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.target === thirdLastItem && entry.isIntersecting) {
              this.fetchHotelPropertiesForScroll();
            }
          });
        },
        {
          root: null,
          threshold: 0.5,
        }
      );

      this.items.forEach((item: any) => {
        this.itemObserver.observe(item);
      });
    },
    async loadMoreProperties() {
      await this.fetchPropertiesWithPage();
    },
    getPropertyDetailRoute(property: string) {
      const {
        hotel_adult_count,
        hotel_child_age,
        hotel_child_count,
        selectedLocation,
        checkin_date,
        checkout_date,
      } = this.$store.state.hotelModule;
      const query = formatQueryParamsforHotelSearch(
        hotel_adult_count,
        hotel_child_age,
        selectedLocation,
        checkin_date,
        checkout_date
      );

      const path = `${formatStringToRoutePath(PATH.HOTEL_PROPERTY_VIEW, {
        id: selectedLocation?.search_id,
      })}`;

      const payload = {
        to: `${selectedLocation.display_name}, ${selectedLocation.sub_display_name}`,
        type: selectedLocation.type,
        "check-in": checkin_date,
        "check-out": checkout_date,
        "adult-travler-count": hotel_adult_count,
        "child-travler-count": hotel_child_count,
      };

      analyticsService.logActionEvent(
        HOTEL_ANALYTICS_EVENTS.HOTEL_SHOW_DETAILS,
        payload
      );

      return `${path}${formatQueryPath(query)}&property=${property}`;
    },
    async fetchPropertiesWithPage() {
      const {
        name,
        sub_name,
        destination,
        type,
        checkin,
        checkout,
        adult,
        child,
      } = this.$route.query;

      const childData = child
        ? (child as string).split(",").map((x) => {
            return {
              age: Number(x),
            };
          })
        : null;

      this.$store.commit("saveCheckInDate", new Date(checkin as string));
      this.$store.commit("saveCheckOutDate", new Date(checkout as string));
      this.$store.commit(
        "updateChildrenAges",
        childData ? childData.map((x) => x.age) : []
      );
      this.$store.commit("saveHotelChildCount", childData?.length || 0);
      this.$store.commit("saveHotelAdultCount", Number(adult as string));
      this.$store.commit("saveSelectedLocation", {
        display_name: name,
        sub_display_name: sub_name,
        label: `${name}, ${sub_name}`,
        search_id: destination,
        type,
      });
      this.currentPage = this.currentPage + 1;
      await this.$store.dispatch("searchPropertiesV2", {
        filters: DEFAULT_FILTERS,
        checkin,
        checkout,
        id: destination,
        page: this.currentPage,
        rooms_occupancy: [
          {
            adults: Number(adult as string),
            ...(childData && { children: childData }),
          },
        ],
        type,
      });

      const hotels = this.$store.getters.hotels;
      const sortedHotels: Property[] = cloneDeep(hotels).sort(
        (a: Property, b: Property) =>
          a?.gross_price.value - b?.gross_price.value
      );

      this.$store.commit("savePriceRange", {
        min: sortedHotels[0]?.gross_price.value,
        max: sortedHotels[sortedHotels.length - 1]?.gross_price.value,
      });

      this.localSelectedFilters = cloneDeep(
        this.$store.state.hotelModule.selectedFilters
      );

      this.minPriceRange = this.$store.state.hotelModule.minPriceRange;
      this.maxPriceRange = this.$store.state.hotelModule.maxPriceRange;
      this.localSelectedFilters.rangeSlider =
        this.$store.state.hotelModule.maxPriceRange;
    },
    async fetchProperties() {
      const {
        name,
        sub_name,
        destination,
        type,
        checkin,
        checkout,
        adult,
        child,
      } = this.$route.query;

      const childData = child
        ? (child as string).split(",").map((x) => {
            return {
              age: Number(x),
            };
          })
        : null;

      this.$store.commit("saveCheckInDate", new Date(checkin as string));
      this.$store.commit("saveCheckOutDate", new Date(checkout as string));
      this.$store.commit(
        "updateChildrenAges",
        childData ? childData.map((x) => x.age) : []
      );
      this.$store.commit("saveHotelChildCount", childData?.length || 0);
      this.$store.commit("saveHotelAdultCount", Number(adult as string));
      this.$store.commit("saveSelectedLocation", {
        display_name: name,
        sub_display_name: sub_name,
        label: `${name}, ${sub_name}`,
        search_id: destination,
        type,
      });

      await this.$store.dispatch("searchProperties", {
        filters: DEFAULT_FILTERS,
        checkin,
        checkout,
        id: destination,
        page: 1,
        rooms_occupancy: [
          {
            adults: Number(adult as string),
            ...(childData && { children: childData }),
          },
        ],
        type,
      });

      const hotels = this.$store.getters.hotels;
      const sortedHotels: Property[] = cloneDeep(hotels).sort(
        (a: Property, b: Property) =>
          a?.gross_price.value - b?.gross_price.value
      );

      this.$store.commit("savePriceRange", {
        min: sortedHotels[0]?.gross_price.value,
        max: sortedHotels[sortedHotels.length - 1]?.gross_price.value,
      });

      this.localSelectedFilters = cloneDeep(
        this.$store.state.hotelModule.selectedFilters
      );

      this.minPriceRange = this.$store.state.hotelModule.minPriceRange;
      this.maxPriceRange = this.$store.state.hotelModule.maxPriceRange;
      this.localSelectedFilters.rangeSlider =
        this.$store.state.hotelModule.maxPriceRange;
    },

    formatRefundable(non_refundable: boolean) {
      return non_refundable ? "Non-Refundable" : "Refundable";
    },
    renderAddress(address: string, city: string, country: string) {
      return `${address}, ${city}, ${country}`;
    },
    renderPrice(grossPrice: number, default_currency_code: string) {
      return `${default_currency_code} ${formatNumber(grossPrice)}`;
    },
    formatUrl(url: string) {
      return formatImageUrl(url);
    },
    handleRemoveChip(
      index: number,
      filter: "propertyRating" | "bookingPolicy" | "supplier"
    ) {
      const selectedFilter = this.localSelectedFilters[filter];
      selectedFilter.splice(index, 1);
    },
    handleViewPropertyClick(property: string) {
      const {
        hotel_adult_count,
        hotel_child_age,
        selectedLocation,
        checkin_date,
        checkout_date,
      } = this.$store.state.hotelModule;
      const query = formatQueryParamsforHotelSearch(
        hotel_adult_count,
        hotel_child_age,
        selectedLocation,
        checkin_date,
        checkout_date
      );

      const path = `${formatStringToRoutePath(PATH.HOTEL_PROPERTY_VIEW, {
        id: selectedLocation?.search_id,
      })}`;
      return `${path}${formatQueryPath(query)}&property=${property}`;
    },
    handleFilter(
      filters: SearchFilters,
      properties: Array<Property>
    ): Property[] {
      let temp = properties;

      temp = temp.filter((item: Property) =>
        filters.propertyRating
          .map((rating) => `${rating}.0`)
          .includes(item.rating.toString())
      );

      temp = temp.filter((item: Property) =>
        filters.supplier.includes(item.supplier)
      );

      temp = temp.filter((item: Property) => {
        if (filters.bookingPolicy.length > 1) {
          return true;
        } else if (filters.bookingPolicy.includes("non-refundable")) {
          return item.non_refundable;
        } else if (filters.bookingPolicy.includes("refundable")) {
          return !item.non_refundable;
        } else {
          return false;
        }
      });

      temp = temp.filter((property: Property) => {
        return (
          property.gross_price.value >= this.minPriceRange &&
          property.gross_price.value <= filters.rangeSlider
        );
      });

      return temp;
    },
    handleUpdateRange(val: number) {
      this.localSelectedFilters.rangeSlider = val;
    },
    initiateScrollView() {
      this.items = document.querySelectorAll(".hotelsList");
      this.itemObserver = new IntersectionObserver(
        (entries) => {
          const thirdLastItem = this.items[this.items.length - 3];

          entries.forEach((entry) => {
            if (entry.target === thirdLastItem && entry.isIntersecting) {
              this.fetchHotelPropertiesForScroll();
            }
          });
        },
        {
          root: null,
          threshold: 1,
        }
      );

      this.items.forEach((item: any) => {
        this.itemObserver.observe(item);
      });
    },
  },
  computed: {
    selectedSupplierFliter() {
      const filters = HOTEL_BOOKING_SUPPLIER_FILTERS;
      const selectedSupplierFliter: Array<string> =
        this.localSelectedFilters.supplier;

      if (filters.length === selectedSupplierFliter.length) {
        return ["supplier"];
      }
      return filters
        .filter((item) => selectedSupplierFliter.includes(item.value))
        .map((item) => item.label);
    },
    selectedRatingFliter() {
      const filters = HOTEL_PROPERTY_RATINGS_FILTERS;
      const selectedRatingFliter: Array<string> =
        this.localSelectedFilters.propertyRating;

      if (filters.length === selectedRatingFliter.length) {
        return ["All Ratings"];
      }
      return filters
        .filter((item) => selectedRatingFliter.includes(item.value))
        .map((item) => item.label);
    },
    selectedBookingPolicyFliter() {
      const filters = HOTEL_BOOKING_POLICY_FILTERS;
      const selectedBookingPolicy: Array<string> =
        this.localSelectedFilters.bookingPolicy;

      if (filters.length === selectedBookingPolicy.length) {
        return ["All Booking Policy"];
      }
      return filters
        .filter((item) => selectedBookingPolicy.includes(item.value))
        .map((item) => item.label);
    },
    propertiesLoading(): boolean {
      return this.$store.getters.isPropertiesLoading;
    },
    ratingFilter() {
      return HOTEL_PROPERTY_RATINGS_FILTERS;
    },
    supplierFilter() {
      return HOTEL_BOOKING_SUPPLIER_FILTERS;
    },
    refundableFilters() {
      return HOTEL_BOOKING_POLICY_FILTERS;
    },
    initialPriceRange() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return getCurrencyFormatter().format(this.minPriceRange);
    },
    getNights() {
      const { checkin, checkout } = this.$route.query;
      const nights = differenceInDays(
        new Date(checkout as string),
        new Date(checkin as string)
      );
      return `${nights} Night${nights > 1 ? "s" : ""}`;
    },
    getTravelersCount(): string {
      const { adult, child } = this.$route.query;

      const child_count = child ? (child as string).split(",").length : 0;
      const tolalCount = Number(adult) + child_count;

      return `Traveler${tolalCount > 1 ? "s" : ""} ${tolalCount}, Room 1`;
    },
    showNoResult() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isLoading = this.propertiesLoading;
      const hotels: [] = this.$store.getters.hotels;
      return !isLoading && hotels.length < 1;
    },
    searchedResultsNotFound() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isLoading = this.propertiesLoading;
      const hotelResponse: HotelPropertyResponse =
        this.$store.state.hotelModule.propertyResponse;
      return (
        !isLoading &&
        hotelResponse.hotels.length > 1 &&
        hotelResponse.searched_property_not_found
      );
    },
  },
  watch: {
    filteredProperties(value: any) {
      if (value.length > 0) {
        setTimeout(() => {
          this.initiateScrollView();
        }, 2000);
      }
    },
    localSelectedFilters: {
      handler: function (filters) {
        const properties: Property[] = this.$store.getters.hotels;
        const filteredData = this.handleFilter(filters, properties);
        this.filteredProperties = filteredData;
      },
      deep: true,
    },
    "$route.query": {
      handler: async function () {
        if (this.allowRouterQueryRequest) {
          await this.fetchProperties();
        }
      },
      immediate: true,
    },
  },
  created() {
    const locations = getPreHotelsSuggestions();
    const formattedLocations = locations.map((item: HotelSearchSuggestion) => {
      return {
        ...item,
        label: `${item.display_name}, ${item.sub_display_name}`,
      };
    });
    this.$store.commit("saveLocations", formattedLocations);
  },
  beforeRouteLeave() {
    this.allowRouterQueryRequest = false;
  },
});
</script>
