import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import {
  GetPropertiesPayload,
  GetPropertyDetailsPayload,
  IConfirmBooking,
  ICreateOTP,
  IHotelIssue,
  ISendOTP,
  InitiatePropertyBookingType,
} from "@/ag-portal-common/types/hotel";
import { HotelEndPointsV3 } from "@/ag-portal-common/constants/hotels";
import { formatString } from "@/ag-portal-common/utils/helpers";

class HotelService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public get(params: string): Promise<IAGResponse<any>> {
    return this.restClient.get(
      HotelEndPointsV3.GET_ALL_LOCATIONS + `?search_query=${params}`
    );
  }

  public fetchProperties(
    params: GetPropertiesPayload
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(HotelEndPointsV3.GET_ALL_PROPERTIES, params);
  }

  public fetchPropertyDetails(
    params: GetPropertyDetailsPayload
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(HotelEndPointsV3.GET_PROPERTY_DETAIL, params);
  }

  public fetchBookingDetails(bookingId: string): Promise<IAGResponse<any>> {
    return this.restClient.get(HotelEndPointsV3.GET_BOOKING + `${bookingId}/`); //not migrated
  }

  public initiatePropertyBooking(
    params: InitiatePropertyBookingType
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(HotelEndPointsV3.INITIATE_BOOKING, params);
  }

  public confirmBooking(params: IConfirmBooking): Promise<IAGResponse<any>> {
    return this.restClient.post(HotelEndPointsV3.CONFIRM_BOOKING, params);
  }

  public cancelBooking(bookingId: string): Promise<IAGResponse<any>> {
    return this.restClient.put(
      formatString(HotelEndPointsV3.CANCEL_BOOKING, bookingId)
    );
  }

  public payForBooking(bookingId: string): Promise<IAGResponse<any>> {
    return this.restClient.put(
      formatString(HotelEndPointsV3.PAY_NOW_BOOKING, bookingId)
    );
  }

  public hotelIssue(params: IHotelIssue): Promise<IAGResponse<any>> {
    return this.restClient.post(HotelEndPointsV3.ISSUE, params);
  }

  public createOtp(params: ICreateOTP): Promise<IAGResponse<any>> {
    return this.restClient.post(HotelEndPointsV3.CREATE_OTP, params);
  }
  public sendOtp(params: ISendOTP): Promise<IAGResponse<any>> {
    return this.restClient.post(HotelEndPointsV3.RESEND_OTP, params);
  }
}

export default HotelService;
