<template>
  <ag-card>
    <ag-heading variant="h2" title="Price Summary" class="margin_bottom_10" />

    <AgPriceSummary
      test-id=""
      v-show="true"
      :info="propertName"
      :price="formattedPrices"
    />
    <AgDiv class="flight_cart_info margin_bottom_10">{{
      getTravelersCount
    }}</AgDiv>

    <slot name="hoteltDetailsActionButtons"> </slot>
  </ag-card>
</template>

<script lang="ts">
import { formatNumber } from "@/ag-portal-common/utils/helpers";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoomCancellationTerm",
  props: {
    propertName: {
      type: String,
      required: true,
    },
    totalCurrency: {
      type: String,
      default: "",
      required: true,
    },
    currencyCode: {
      type: String,
      default: "",
      required: true,
    },
    travelerCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    formattedPrices() {
      return this.currencyCode + " " + formatNumber(this.totalCurrency);
    },
    getTravelersCount(): string {
      const { adult, child } = this.$route.query;
      let totalCount = 0;
      if (adult) {
        const child_count = child ? (child as string).split(",").length : 0;
        totalCount = Number(adult) + child_count;
      } else {
        totalCount = this.travelerCount;
      }

      return `Traveler${totalCount > 1 ? "s" : ""} ${totalCount}, Room 1`;
    },
  },
});
</script>
