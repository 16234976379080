<template>
  <ag-card>
    <ag-heading variant="h2" title="Payment Policy" class="margin_bottom_10" />

    <AgPriceSummary
      test-id=""
      info="Payment will be deducted on"
      :price="formattedDate(agPaymentDate)"
      class="margin_bottom_10"
    />

    <AgDiv>
      <b>Note:</b> Make sure you have sufficient funds in your account at the
      time of payment or booking will cancel automatically
    </AgDiv>
  </ag-card>
</template>

<script lang="ts">
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { defineComponent } from "vue";
import { FORMAT_DD_MM_YYYY_WITH_SPACE } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "PaymentPolicy",
  props: {
    agPaymentDate: {
      type: String,
      default: "",
    },
  },
  created() {
    console.log("In PaymentPolicy");
  },
  methods: {
    formattedDate(date: string) {
      return getFormattedDateTime(new Date(date), FORMAT_DD_MM_YYYY_WITH_SPACE);
    },
  },
});
</script>
