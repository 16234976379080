export const HOTEL_ANALYTICS_EVENTS = {
  AG_HOTEL_SEARCH_APPLIED: "search_applied",
  AG_HOTEL_PROPERTY_BOOKING: "book_property",
  AG_HOTEL_BOOKING_ISSUANCE: "booking_issuance",

  HOTEL_QUICK_SEARCH: "hotels-quick-search-clicked",
  HOTEL_SEARCH: "hotels-search-clicked",
  HOTEL_SHOW_DETAILS: "hotels-show-details-clicked",
  HOTEL_BOOK_NOW: "hotel-room-book-now",
};
