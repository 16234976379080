export interface IHotelFacility {
  Icon: string;
  value: string;
}

export const HotelFacilitiesValues: { [key: string]: IHotelFacility } = {
  Sauna: {
    Icon: "HotTubIcon", // Those will be replaced with actuall icons
    value: "Sauna",
  },
  AirConditioning: {
    Icon: "AcUnitIcon",
    value: "Air Conditioning",
  },
  CarRentDesk: {
    Icon: "DriveEtaIcon",
    value: "Car Rent Desk",
  },
  Hairdresser: {
    Icon: "FitbitIcon",
    value: "Hair Dresser",
  },
  Concierge: {
    Icon: "EmojiPeopleIcon",
    value: "Concierge",
  },
  MeetingBanquetFacilities: {
    Icon: "MeetingRoomIcon",
    value: "Meeting Banquet",
  },
  Spa: {
    Icon: "SpaIcon",
    value: "Spa",
  },
  WheelchairAccess: {
    Icon: "AccessibleIcon",
    value: "Wheel Chair",
  },
  Salon: {
    Icon: "EventSeatIcon",
    value: "Salon",
  },
  CurrencyExchangeService: {
    Icon: "StoreIcon",
    value: "Currency Exchange",
  },
  SafetyBox: {
    Icon: "HealthAndSafetyIcon",
    value: "Safety Box",
  },
  ChildrensClub: {
    Icon: "GroupsIcon",
    value: "Children's Club",
  },
  TourDesk: {
    Icon: "ContactSupportIcon",
    value: "Tour Desk",
  },
  Elevators: {
    Icon: "ElevatorIcon",
    value: "Elevators",
  },
  HighSpeedInternet: {
    Icon: "WifiIcon",
    value: "High Speed Internet",
  },
  MultilingualStaff: {
    Icon: "TranslateIcon",
    value: "Multilingual Staff",
  },
  Doctoroncall: {
    Icon: "MedicalServicesIcon",
    value: "Doctor On Call",
  },
  Breakfast: {
    Icon: "FreeBreakfastIcon",
    value: "Breakfast",
  },
};

export const getHotelFacility = (value: string): IHotelFacility => {
  const keys = Object.keys(HotelFacilitiesValues);
  const foundFacilityKey = keys.find((key) => {
    return key.toLowerCase().includes(value.toLowerCase());
  });
  if (foundFacilityKey) {
    if (HotelFacilitiesValues[foundFacilityKey]) {
      //   return HotelFacilitiesValues[foundFacilityKey];
      return {
        Icon: "yellowStar",
        value: value,
      };
    }
  }
  return {
    Icon: "yellowStar",
    value: value,
  };
};
