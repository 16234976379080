<template>
  <AgDiv class="widget_border">
    <AgRow test-id="">
      <AgColumn test-id="" sm="3" cols="12">
        <AgContactInfo icon-name="pakFlag" :title="renderFirstName" />
      </AgColumn>
      <AgColumn test-id="" sm="3" cols="12">
        <AgContactInfo :title="guest?.last_name" />
      </AgColumn>
      <AgColumn test-id="" sm="3" cols="12">
        <AgContactInfo icon-name="nicIcon" :title="guest?.date_of_birth" />
      </AgColumn>
      <AgColumn test-id="" sm="3" cols="12">
        <AgContactInfo
          :icon-name="renderPassengerType.icon"
          :title="renderPassengerType.type"
        />
      </AgColumn>
    </AgRow>
  </AgDiv>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Guest } from "@/ag-portal-common/types/hotel";

export default defineComponent({
  name: "PassengerDetailCard",
  props: {
    guest: { type: Object as PropType<Guest> },
  },
  computed: {
    renderFirstName() {
      return `${this.guest?.title} ${this.guest?.first_name}`;
    },
    renderPassengerType() {
      const type = this.guest?.type;
      return {
        type,
        icon: `${type?.toLowerCase()}Icon`,
      };
    },
  },
});
</script>
