import {
  HotelLocationType,
  HotelSearchSuggestion,
} from "../types//hotel/index";

export const getPreHotelsSuggestions = (): HotelSearchSuggestion[] => {
  // ****************************************************************
  // ******* Prod Locations *******
  if (process.env.NODE_ENV === "production") {
    return [
      {
        search_id: "VW5V35ON2LS",
        display_name: "Karachi",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Tomb_Jinnah.jpg/150px-Tomb_Jinnah.jpg",
      },
      {
        search_id: "VW5V35IOQL0",
        display_name: "Lahore",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Royal_mosque_Lahore.jpg/268px-Royal_mosque_Lahore.jpg",
      },
      {
        search_id: "VW5V35ULGSU",
        display_name: "Islamabad",
        sub_display_name: "Pakistan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Faisal_Mosque%2C_Islamabad_III.jpg/266px-Faisal_Mosque%2C_Islamabad_III.jpg",
      },
      {
        search_id: "VW5V35IORWZ",
        display_name: "Mecca",
        sub_display_name: "Saudi Arabia",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRcKsoWVDpgVvpMii-qap7G9ekBg8Tzo-YhNmA2uTHwU6c0V1MoKDcPljpoVp0-dDY8",
      },
      {
        search_id: "VW5V366I4KF",
        display_name: "Medina",
        sub_display_name: "Saudi Arabia",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTjYcVXBiM-9MuQnZK-QPLRQKozIBuKmTbolTbg0-D486S12vxYmYUwVkqtMjRJDo4W",
      },
      {
        search_id: "VW5V35IORH8",
        display_name: "Dubai",
        sub_display_name: "United Arab Emirates",
        type: HotelLocationType.CITY,
        image:
          "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTnavcE0R1cW9vbZSJU9A_92W41dYEqmnQRVz51osw9lq-dYLXMRz2uqIIL1LrWzvQN",
      },
      {
        search_id: "VW5V350TQEL",
        display_name: "Istanbul",
        sub_display_name: "Turkey",
        type: HotelLocationType.CITY,
        image:
          "https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcQMTvwtG7h0Qvf-Cb9yl9JomL5LGkuCdg9Y5Pu_K85MPufi-Mg9BWauCTjsTTey6qch",
      },
      {
        search_id: "VW5V35IORM8",
        display_name: "Baku",
        sub_display_name: "Azerbaijan",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQ0D_WKwN09neI6JL37-wxJEcqbhAPL8jaVi8OUrZlHEGGthPEYQZbm6OR2XVIwPhfz",
      },
      {
        search_id: "VW5V34UVEH0",
        display_name: "Kuala Lumpur",
        sub_display_name: "Malaysia",
        type: HotelLocationType.CITY,
        image:
          "https://cdn.britannica.com/49/102749-050-B4874C95/Kuala-Lumpur-Malaysia.jpg",
      },
      {
        search_id: "VW5V360H9SX",
        display_name: "Phuket",
        sub_display_name: "Thailand",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Phuket_Aerial.jpg/800px-Phuket_Aerial.jpg",
      },
      {
        search_id: "VW5V35CQEJE",
        display_name: "London",
        sub_display_name: "United Kingdom",
        type: HotelLocationType.CITY,
        image:
          "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTF9OtLxnbxSpLFzyhzXERKiAbnHlG25WfRzxRTNcRiTk0lHvsOXNKH9KNAOvTWi_sS",
      },
      {
        search_id: "VW5V35ON5MT",
        display_name: "Denpasar",
        sub_display_name: "Indonesia",
        type: HotelLocationType.CITY,
        image:
          "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcS1BWJs0qug7Cw0UQtD-4vLv_e_TzBdLftSukG8FSh_Vv_mFgh9irMws_LYErAuJvoX",
      },
      {
        search_id: "VW5V35ULEKL",
        display_name: "Tokyo",
        sub_display_name: "Japan",
        type: HotelLocationType.CITY,
        image:
          "https://upload.wikimedia.org/wikipedia/commons/b/b2/Skyscrapers_of_Shinjuku_2009_January.jpg",
      },
    ];
  }
  // ****************************************************************
  // ******* Dev Locations *******
  return [
    {
      search_id: "VYQ0MRR387O",
      display_name: "Karachi",
      sub_display_name: "Pakistan",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Tomb_Jinnah.jpg/150px-Tomb_Jinnah.jpg",
    },
    {
      search_id: "VYQ0MQRAGG3",
      display_name: "Lahore",
      sub_display_name: "Pakistan",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Royal_mosque_Lahore.jpg/268px-Royal_mosque_Lahore.jpg",
    },
    {
      search_id: "VYQ0MQRAGG3",
      display_name: "Islamabad",
      sub_display_name: "Pakistan",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Faisal_Mosque%2C_Islamabad_III.jpg/266px-Faisal_Mosque%2C_Islamabad_III.jpg",
    },
    {
      search_id: "VYQ0MRF6L9T",
      display_name: "Mecca",
      sub_display_name: "Saudi Arabia",
      type: HotelLocationType.CITY,
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRcKsoWVDpgVvpMii-qap7G9ekBg8Tzo-YhNmA2uTHwU6c0V1MoKDcPljpoVp0-dDY8",
    },
    {
      search_id: "VYQ0MRR0GJF",
      display_name: "Medina",
      sub_display_name: "Saudi Arabia",
      type: HotelLocationType.CITY,
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTjYcVXBiM-9MuQnZK-QPLRQKozIBuKmTbolTbg0-D486S12vxYmYUwVkqtMjRJDo4W",
    },
    {
      search_id: "VYQ0MRF6KXK",
      display_name: "Dubai",
      sub_display_name: "United Arab Emirates",
      type: HotelLocationType.CITY,
      image:
        "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcTnavcE0R1cW9vbZSJU9A_92W41dYEqmnQRVz51osw9lq-dYLXMRz2uqIIL1LrWzvQN",
    },
    {
      search_id: "VYQ0MRL4W5V",
      display_name: "Istanbul",
      sub_display_name: "Turkey",
      type: HotelLocationType.CITY,
      image:
        "https://t3.gstatic.com/licensed-image?q=tbn:ANd9GcQMTvwtG7h0Qvf-Cb9yl9JomL5LGkuCdg9Y5Pu_K85MPufi-Mg9BWauCTjsTTey6qch",
    },
    {
      search_id: "VYQ0MRR394O",
      display_name: "Baku",
      sub_display_name: "Azerbaijan",
      type: HotelLocationType.CITY,
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQ0D_WKwN09neI6JL37-wxJEcqbhAPL8jaVi8OUrZlHEGGthPEYQZbm6OR2XVIwPhfz",
    },
    {
      search_id: "VYQ0MQFDQRJ",
      display_name: "Kuala Lumpur",
      sub_display_name: "Malaysia",
      type: HotelLocationType.CITY,
      image:
        "https://cdn.britannica.com/49/102749-050-B4874C95/Kuala-Lumpur-Malaysia.jpg",
    },
    {
      search_id: "VYQ0MRKZKQM",
      display_name: "Phuket",
      sub_display_name: "Thailand",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Phuket_Aerial.jpg/800px-Phuket_Aerial.jpg",
    },
    {
      search_id: "VYQ0MR39W1Q",
      display_name: "London",
      sub_display_name: "United Kingdom",
      type: HotelLocationType.CITY,
      image:
        "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcTF9OtLxnbxSpLFzyhzXERKiAbnHlG25WfRzxRTNcRiTk0lHvsOXNKH9KNAOvTWi_sS",
    },
    {
      search_id: "VYQ0MQ9FGUY",
      display_name: "Denpasar",
      sub_display_name: "Indonesia",
      type: HotelLocationType.CITY,
      image:
        "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcS1BWJs0qug7Cw0UQtD-4vLv_e_TzBdLftSukG8FSh_Vv_mFgh9irMws_LYErAuJvoX",
    },
    {
      search_id: "VYQ0MRR0F0P",
      display_name: "Tokyo",
      sub_display_name: "Japan",
      type: HotelLocationType.CITY,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/b/b2/Skyscrapers_of_Shinjuku_2009_January.jpg",
    },
  ];
};
