import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HotelLocationAutoComplete = _resolveComponent("HotelLocationAutoComplete")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgTravelerCountItem = _resolveComponent("AgTravelerCountItem")!
  const _component_AgSelect = _resolveComponent("AgSelect")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgTravelerCountDropdown = _resolveComponent("AgTravelerCountDropdown")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgCard = _resolveComponent("AgCard")!

  return (_openBlock(), _createBlock(_component_AgCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ag_column, {
            xs: "12",
            sm: "12",
            md: "6",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AgDiv, { class: "combine_flight" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_row, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        cols: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_HotelLocationAutoComplete, {
                            "onUpdate:location": _ctx.searchLocation,
                            "onOnSelect:location": _ctx.onSelectLocation,
                            label: "From",
                            name: "location",
                            items: _ctx.locations,
                            value: _ctx.$store.state.hotelModule.selectedLocation,
                            "is-loading": _ctx.$store.getters.isLocationsFetching
                          }, null, 8, ["onUpdate:location", "onOnSelect:location", "items", "value", "is-loading"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        cols: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_calendar, {
                            label: "Check In",
                            modelValue: _ctx.$store.state.hotelModule.checkin_date,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$store.state.hotelModule.checkin_date) = $event)),
                            "min-date": new Date(),
                            date: _ctx.$store.state.hotelModule.checkin_date,
                            "calendar-name": "checkin",
                            "onUpdate:checkin": _ctx.changeCheckInDate,
                            IconName: "calendar"
                          }, null, 8, ["modelValue", "min-date", "date", "onUpdate:checkin"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            xs: "12",
            sm: "12",
            md: "6",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AgDiv, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        cols: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_calendar, {
                            label: "Check Out",
                            modelValue: _ctx.$store.state.hotelModule.checkout_date,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$store.state.hotelModule.checkout_date) = $event)),
                            "min-date": _ctx.checkoutMinDate,
                            date: _ctx.$store.state.hotelModule.checkout_date,
                            "calendar-name": "checkout",
                            "onUpdate:checkout": _ctx.changeCheckOutDate,
                            IconName: "calendar"
                          }, null, 8, ["modelValue", "min-date", "date", "onUpdate:checkout"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_column, {
                        xs: "12",
                        sm: "12",
                        md: "6",
                        cols: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            title: "Traveler",
                            variant: "label"
                          }),
                          _createVNode(_component_AgTravelerCountDropdown, {
                            "test-id": "",
                            label: _ctx.renderTravelerLabel,
                            class: "hotel_traveler"
                          }, {
                            Items: _withCtx(() => [
                              _createVNode(_component_AgTravelerCountItem, {
                                icon: "adultIcon",
                                name: "AdultCount",
                                count: _ctx.$store.state.hotelModule.hotel_adult_count,
                                "onUpdate:AdultCount": _ctx.updateAdultCount,
                                label: "Adult (18+)",
                                "test-id": ""
                              }, null, 8, ["count", "onUpdate:AdultCount"]),
                              _createVNode(_component_AgTravelerCountItem, {
                                icon: "childrenIcon",
                                name: "ChildCount",
                                "onUpdate:ChildCount": _ctx.updateChildCount,
                                count: _ctx.$store.state.hotelModule.hotel_child_count,
                                label: "Children (Under 1 to less then 12)",
                                "test-id": ""
                              }, {
                                childInfo: _withCtx(() => [
                                  _createVNode(_component_AgDiv, {
                                    "test-id": "",
                                    class: "margin_top_20 px-15 px-5"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_AgRow, { "test-id": "" }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelChildAges, (item, index) => {
                                            return (_openBlock(), _createBlock(_component_AgColumn, {
                                              "test-id": "",
                                              sm: "12",
                                              md: "6",
                                              key: index
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_AgSelect, {
                                                  value: item,
                                                  items: _ctx.childAges,
                                                  label: _ctx.renderAgeSelecterLabel(index),
                                                  "test-id": "",
                                                  variant: "outlined",
                                                  onChange: (value) => _ctx.handleAgeSelectChange(value, index),
                                                  class: "child_age_select"
                                                }, null, 8, ["value", "items", "label", "onChange"])
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["onUpdate:ChildCount", "count"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_AgDiv, { class: "text-center margin_top_20" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_g_button, {
            "test-id": "fligh-search",
            "is-loading": _ctx.propertiesLoading,
            onClick: _ctx.handleSearchClick
          }, {
            default: _withCtx(() => [
              _createTextVNode("Search Hotels")
            ]),
            _: 1
          }, 8, ["is-loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}