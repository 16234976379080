import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc2af878"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_FaciltyChip = _resolveComponent("FaciltyChip")!
  const _component_agdiv = _resolveComponent("agdiv")!
  const _component_AgImage = _resolveComponent("AgImage")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgFancyBox = _resolveComponent("AgFancyBox")!
  const _component_AgPropertyReview = _resolveComponent("AgPropertyReview")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPhoneField = _resolveComponent("AgPhoneField")!
  const _component_AGTextField = _resolveComponent("AGTextField")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgTravelDetail = _resolveComponent("AgTravelDetail")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_TravelerCard = _resolveComponent("TravelerCard")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_PaymentPolicy = _resolveComponent("PaymentPolicy")!
  const _component_CancellationPolicy = _resolveComponent("CancellationPolicy")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_ag_row = _resolveComponent("ag-row")!

  return (_ctx.$store.state.hotelModule.isLoading)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_ag_row, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_column, {
            cols: "12",
            sm: "12",
            md: "12",
            lg: "9"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AgPropertyReview, {
                "test-id": "",
                rating: _ctx.bookingDetails?.property?.rating,
                "hotel-thumb": _ctx.formatUrl(_ctx.bookingDetails?.property.main_image_url || ''),
                "hotel-name": _ctx.bookingDetails?.property?.name,
                "room-quote": _ctx.roomQoute(_ctx.bookingDetails?.room?.room_type),
                "checkin-date": _ctx.renderDate(_ctx.bookingDetails?.checkin),
                "checkout-date": _ctx.renderDate(_ctx.bookingDetails?.checkout),
                "no-of-night": 
          _ctx.getNights(_ctx.bookingDetails?.checkin, _ctx.bookingDetails?.checkout)
        
              }, {
                hotelImages: _withCtx(() => [
                  _createVNode(_component_AgFancyBox, { class: "d-flex" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_div, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_div, { class: "bed-and-breakfast-container" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.bookingDetails?.room?.meal_board_basis), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_agdiv, { style: {"display":"flex","flex-wrap":"wrap"} }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedFacilities, (item, index) => {
                                return (_openBlock(), _createBlock(_component_FaciltyChip, {
                                  key: index,
                                  name: item.value,
                                  "icon-name": item.Icon
                                }, null, 8, ["name", "icon-name"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertyImages, (item, index) => {
                        return (_openBlock(), _createBlock(_component_AgDiv, { key: index }, {
                          default: _withCtx(() => [
                            _createElementVNode("a", {
                              "data-fancybox": "gallery",
                              href: item
                            }, [
                              _createVNode(_component_AgImage, {
                                "test-id": "",
                                src: item,
                                class: "mr-10",
                                width: "52px",
                                height: "52px"
                              }, null, 8, ["src"])
                            ], 8, _hoisted_1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["rating", "hotel-thumb", "hotel-name", "room-quote", "checkin-date", "checkout-date", "no-of-night"]),
              _createVNode(_component_AgCard, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgIconInfoBar, {
                    icon: "contactPhoneIcon",
                    title: "Contact Details",
                    "test-id": ""
                  }),
                  _createVNode(_component_ag_accordion, {
                    class: "flight_accordion_wrap margin_bottom_0",
                    "panel-value": [0]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_accordion_panel, null, {
                        flightInfo: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            variant: "h3",
                            title: "Lead Traveler",
                            class: "margin_bottom_0"
                          })
                        ]),
                        tabSection: _withCtx(() => [
                          _createVNode(_component_AgRow, {
                            "test-id": "",
                            class: "padding_top_20"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AgColumn, {
                                "test-id": "",
                                md: "12",
                                lg: "7",
                                cols: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AgRow, { "test-id": "" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_AgColumn, {
                                        "test-id": "",
                                        md: "12",
                                        lg: "9"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_AgPhoneField, {
                                            "test-id": "",
                                            "default-country-code": "PK",
                                            "info-message": "Please input the traveler’s\n                        number here to receive flight updates",
                                            onInput: _ctx.handleMobileFieldChange,
                                            error: _ctx.renderMobileNumberError
                                          }, null, 8, ["onInput", "error"]),
                                          _createVNode(_component_AGTextField, {
                                            label: "Email",
                                            "test-id": "",
                                            variant: "outlined",
                                            onInput: _ctx.handleEmailFieldChange,
                                            error: _ctx.renderEmailError
                                          }, null, 8, ["onInput", "error"])
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_AgColumn, {
                                "test-id": "",
                                md: "12",
                                lg: "5",
                                cols: "12"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AgTravelDetail, {
                                    "test-id": "",
                                    items: {
                      'Mobile No': _ctx.$store.state.hotelModule.mobileNumber,
                      Email: _ctx.$store.state.hotelModule.email,
                    },
                                    phone: _ctx.$store.state.hotelModule.mobileNumber,
                                    email: _ctx.$store.state.hotelModule.email
                                  }, {
                                    headingArea: _withCtx(() => [
                                      _createVNode(_component_AgHeading, {
                                        variant: "h2",
                                        title: "Contact Details",
                                        class: "margin_bottom_5"
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["items", "phone", "email"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.travelers, (traveler, index) => {
                return (_openBlock(), _createBlock(_component_TravelerCard, {
                  traveler: traveler,
                  errors: _ctx.errors,
                  index: index,
                  key: index
                }, null, 8, ["traveler", "errors", "index"]))
              }), 128)),
              _createVNode(_component_AgDiv, {
                "test-id": "",
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AGButton, {
                    "test-id": "",
                    type: "button",
                    onClick: _ctx.handleConfirmBooking,
                    "is-loading": _ctx.isConfirmBookingLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Confirm Booking ")
                    ]),
                    _: 1
                  }, 8, ["onClick", "is-loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ag_column, {
            cols: "12",
            sm: "12",
            md: "12",
            lg: "3"
          }, {
            default: _withCtx(() => [
              (_ctx.bookingDetails?.ag_payment_date)
                ? (_openBlock(), _createBlock(_component_PaymentPolicy, {
                    key: 0,
                    "ag-payment-date": _ctx.bookingDetails?.ag_payment_date
                  }, null, 8, ["ag-payment-date"]))
                : _createCommentVNode("", true),
              _createVNode(_component_CancellationPolicy, {
                "cancellation-terms": _ctx.bookingDetails?.room?.cancellation_terms
              }, null, 8, ["cancellation-terms"]),
              _createVNode(_component_PriceSummary, {
                "propert-name": _ctx.bookingDetails?.property?.name,
                "total-currency": _ctx.bookingDetails?.price.value,
                "currency-code": _ctx.bookingDetails?.price.currency
              }, null, 8, ["propert-name", "total-currency", "currency-code"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}