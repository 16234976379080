<template>
  <ag-heading :title="localLabel" variant="label"></ag-heading>
  <AgAutoComplete
    v-model="searchValue"
    icon="calendar"
    :items="formattedLocations"
    item-label="label"
    @keyup="onChangeSearchField"
    :is-loading="isLoading"
    @update:modelValue="onUpdateModelValue"
    class="flightField"
    :value="value"
    :error="error"
    :error-message="errorMessage"
  >
    <template #dropdownItem="{ itemProps, itemData }">
      <AgTextFieldDropDownItem v-bind="itemProps">
        <AgDiv class="flightDropdownWrap">
          <AgIcon
            class="flight_icon_wrap"
            test-id=""
            name="mapPinIcon"
          ></AgIcon>
          <AgDiv class="airport_name">
            {{ itemData.display_name }}
            <AgDiv>{{ itemData.sub_display_name }}</AgDiv>
          </AgDiv>
        </AgDiv>
      </AgTextFieldDropDownItem>
    </template>
  </AgAutoComplete>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { HotelSearchSuggestion } from "@/ag-portal-common/types/hotel/index";

export default defineComponent({
  name: "HotelSearchAutoComplete",
  props: {
    label: {
      type: String,
      default: "From",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: Object as PropType<HotelSearchSuggestion>,
      required: true,
    },
    items: {
      type: Array,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchValue: "",
      localLabel: this.label,
      locations: [] as Array<HotelSearchSuggestion>,
      selectedValue: null,
    };
  },
  computed: {
    formattedLocations() {
      const data = this.locations.map((item: HotelSearchSuggestion) => {
        return {
          ...item,
          label: item?.label,
          value: item,
        };
      });
      return data;
    },
  },
  methods: {
    onChangeSearchField(e: any) {
      this.searchValue = e.target.value;
      this.$emit(`update:${this.name}`, e.target.value);
    },
    onUpdateModelValue(value: HotelSearchSuggestion) {
      this.$emit(`onSelect:${this.name}`, value);
      this.selectedValue != value;
    },
  },

  watch: {
    items: {
      handler(places: Array<HotelSearchSuggestion>) {
        this.locations = places;
      },
      deep: true,
    },
    value: {
      handler(newVal) {
        const val = {
          ...newVal,
          label: newVal?.label,
          value: newVal,
        };
        this.selectedValue != val;
      },
    },
  },
});
</script>
